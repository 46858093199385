.chat-list-item {
    display: flex;
    &__message-container {
        display: flex;
        align-items: center;
        margin-top: 4px;
    }
    &.active {
        background: #F5F8FA;
    }
    &.group-list-item__selected{
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 80px;
        border: none;
        text-align: center;
        padding: 0;

        .chat-list-item__image-container__image{
            margin: 0;
        }
        .chat-list-details-container.without-message{
            position: unset;
            width: 100%;
            .chat-list-meta-container{
                max-width: unset;
                width: 100%;
                .message-primary-text.text-bold{
                    width: 100%;
                }
            }
        }
        &::after{
            content: none;
        }
        .input__container{
            position: absolute;
            top: 0;
            right: 0;

           .chat-checkbox-input:checked + .chat-checkbox-input-box{

            background: url("assets/images/common/icons/close-filled-red.svg") no-repeat center center;
            background-size: 20px;
           }
        }
    }
}

.chat-list {
    &__message {
        display: flex;
        flex: 1;
        align-items: center;
        max-width: calc(100% - 20px);
        &__image {
            width: 18px;
            height: auto;
            margin-right: 10px;
        }
        &__text {
            @include get-font($f16, $fw-r, $lighterBlack, $lhr);
            width: calc(100% - 28px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0;
            // margin-top: 4px;
        }
    }
    &__unread-container {
        height: 20px;
        width: 20px;
        border-radius: 20px;
        background: $notificationRed;
        display: flex;
        justify-content: center;
        align-items: center;
        // font-size: 12px;
        // font-weight: normal;
        // color: $white;
        margin-bottom: 0;
        @include get-font($f12, $fw-r, $white);
    }
    &.group-list{
        display: flex;
        align-items: center;
        gap: 15px;
        overflow-x: scroll;
        padding:10px;
        margin: 0 10px;
        border-bottom: 1px solid #dfdfdf;
        @include getDefaultScrollbar();

        ~ .chat-modal-content-container{
            height: 340px;
        }
    }
}

.participants-list {
    .chat-list-item{
        align-items: center;
    }
}