.hide {
  display: none;
}

.tag-box {
  &__overflow-div {
    max-height: 50vh;
    overflow-y: auto;
    @include getDefaultScrollbar();
    @media only screen and (max-width: $onlyMobile) {
    min-height: 32vh;
    }
  }
  &__item {
    background: rgba($pink, 0.2);
    margin-left: 1px;
    padding-bottom: 1px;
  }
}
.tag-box,
.tag-box-select {
  &__input {
    border: 0;
    outline: 0;
  }
  &__suggestions {
    width: 360px;
    max-width: 100%;
    @extend %border-box-shadow;
    box-sizing: border-box;
    border-radius: 10px;
    border-top-left-radius: 0;
    overflow: hidden;
    z-index: 20 !important;
    &__item {
      @include get-font($f14, $fw-r, $black, $lh);
      padding: 8px 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid $gray;
      &--focused {
        background: $superLightBlack;
        cursor: pointer;
      }
      &:last-child {
        margin-bottom: 0;
        border-bottom: 0px;
      }
      &__icon {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        margin-right: 8px;
      }
      &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      > div {
        display: inline-flex;
        align-items: center;
      }
    }
    &__invite {
      margin-left: auto;
      margin-right: 12px;
      @include get-font($f12, $fw-sb, $green, $lh);
    }
  }
}

.tag-box-select {
  &__control {
    // border-color: $grayBackground !important;
    border: 0 !important;
    box-shadow: none !important;
    min-height: 36px !important;
  }
  &__value-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &__multi-value {
    &__label {
      @include get-font($f14, $fw-r, $black, $lh);
      max-width: 200px;
    }
    min-height: 21px !important;
    height: auto !important;
    border-radius: 14px !important;
    margin-right: 8px !important;
    margin-bottom: 8px !important;
    &__remove {
      cursor: pointer;
      &:hover {
        background-color: $lightestBlack !important;
        color: $white !important;
        border-top-right-radius: 14px !important;
        border-bottom-right-radius: 14px !important;
      }
    }
  }
  &__indicators {
    display: none !important;
  }
}

.image-placeholder {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  &--alcohol {
    width: 230px;
    height: 110px;
    cursor: pointer;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__input {
    position: absolute;
    opacity: 0;
  }
  &__image {
    position: relative;
    display: inline-block;
    background: $white;
    width: 100px;
    height: 100px;
    border: 1px solid $superLightBlack;
    border-radius: 10px;
    &--alcohol {
      width: 230px;
      height: 110px;
    }
    &--gray {
      border-color: transparent;
      background: $grayBackground;
    }
    &--blue {
      border-color: transparent;
      background: $aquaBlue;
      width: 230px;
      height: 110px;
      cursor: pointer;
      &--select {
        display: flex;
        flex-direction: column;
      }
    }
    &--centered {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__remove {
    @extend %appearance-none;
    position: absolute;
    right: 4px;
    top: 4px;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $lighterBlack;
    padding: 0;
    border: 0;
    cursor: pointer;
    img {
      width: 10px;
      height: auto;
    }
  }
}

.error-text {
  @include get-font($f12, $fw-r, $removeRed, $lh);
  margin: 8px;
}
.error-text-left {
  margin-left: 3px;
}



.mobile-hidden {
  @media only screen and (max-width: $onlyMobile) {
    display: none !important;
  }
}

.tab-hidden {
  @media only screen and (max-width: $tab) {
    display: none !important;
  }
}

.generic-loader {
  img {
    width: 40px;
    @media only screen and (max-width: $onlyMobile) {
      width: 16px;
    }
  }
  &--centered {
    text-align: center;
    &-both{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  &--with-margin {
    margin: 16px 0;
  }
}

.links-footer {
  margin-top: 16px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    li {
      display: inline-block;
      vertical-align: top;
      padding: 0 14px;
      @include get-font($f14, $fw-r, $lightBlack, $lh);
      position: relative;
      a {
        color: $lightBlack;
        text-decoration: none;
      }
      &:after {
        position: absolute;
        right: 0;
        content: " ";
        width: 2px;
        height: 2px;
        border-radius: 2px;
        top: 50%;
        transform: translateY(-50%);
        background: $lightBlack;
      }
      &:last-child {
        padding-right: 0;
        &:after {
          display: none;
        }
      }
    }
  }
  p {
    border-top: 1px solid $superLightBlack;
    margin-top: 8px;
    padding-top: 8px;
    @include get-font($f14, $fw-r, $lightBlack, $lh);
    text-align: center;
  }
}

.verified {
  &__text {
    max-width: calc(100% - 24px);
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }
}

.custom-tool-tip {
  position: relative;
  &__message {
    @include get-font($f12, $fw-m, $white);
    background: $black;
    padding: 8px 6px;
    min-height: 46px;
    box-sizing: border-box;
    border-radius: 4px;
    min-width: 150px;
    border-radius: 10px;
  }
  &__arrow {
    top: 0;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    background: $black;
    position: relative;
    margin-top: -6px;
    z-index: 0;
  }
  &__container {
    position: absolute;
    top: 0;
    margin-top: -48px;
  }
}

.btn-disabled {
  opacity: 0.65;
  cursor: not-allowed !important;
}

.display-flex {
  display: flex;
}

.show-more-span-btn {
  color: $blue;
  cursor: pointer;
}

.cropperOverlap {
  top: 0 !important;
  border-radius: 6px 6px 5px 5px !important;
  height: 100% !important;
  
  .default-modal__backdrop{
    z-index: 110;
  }
}

.imageUploadBtn {
  &__removeImage {
    position: absolute;
    margin-top: 8px;
    cursor: pointer;
    width: 20px !important;
    right: 10px;
  }
  &__inner {
    width: fit-content;
    margin: auto;
    position: relative;
    &__buttonText {
      @include get-font($f14, $fw-m, $copiedBackground, $lhOnboarding);
    }
    &__sizeText {
      @include get-font($f12, $fw-sb, $copiedBackground, $f18);
      left: 35%;
      margin-top: 8px;
    }
  }
  &__text {
    &__wrapper {
      width: 43%;
      padding: 0 1em;
      height: 3.5em;
      margin: auto;
      background: $white;
      border-radius: 3em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 300ms ease-in-out;
      display: flex;
      flex-direction: row;
      span {
        margin-left: 0.5em;
      }
    }
  }
}

.createbar__toast{
  .toast{
    &_blockedcompany{
      margin: auto;
      max-width: 255px;
    }
    &_blockedprofile{
      margin: auto;
      max-width: 255px;
    }
    &_unblockedprofile{
      margin: auto;
      max-width: 242px;
    }
    &_alreadyblockedprofile{
      margin: auto;
      max-width: 410px !important;
    }
    &_alreadyblockedcompany{
      margin: auto;
      max-width: 410px !important;
    }
    &_post{
      margin: auto;
      max-width: 300px;
    }
  }
} 

// class for gif 

.gif_image{
  object-fit: contain !important;
  aspect-ratio: 5/4 !important;
  object-position: center !important;
}

.big_gif_image{
  object-fit: contain !important;
  aspect-ratio: 16/9 !important;
  object-position: center !important;
}

.background_gif{
  background-color: white !important;
}
.formatText{
  left: 21% !important;
}

// common dropdown
.common-dropdown {
  position: relative;
  min-height: fit-content;
  height: fit-content;

  .dropdown-button{
    all: unset;
    cursor: pointer;
    rotate: 90deg;
    
    &__text{
      @include get-font($f20,$fw-m,$black,1.5);
    }
  }
  .submenu{
    position: absolute;
    right: 0;
    z-index: 1;

    &--list{
      margin: 0;
      padding: 0;
      list-style: none;
      padding: 0;
      width: 166px;
      border-radius: 10px;
      border-top-right-radius: 0;
      background-color: $white;
      box-shadow: 0 2px 4px 0 $tastingCardShadow;
      border: solid 0.5px $tastingCardShadow;

      &__item{
       &__btn{
        padding: 8px 12px;
        box-sizing: border-box;
        border: 0;
        cursor: pointer;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        align-items: center;
        background-color: transparent;

        img{
          height: 18px;
          width: 18px;
        }

        &__text{
          @include get-font($f14,$fw-r,$black,1.5)
        }

        &:hover{
          background-color: $grayBackground;
        }
       }
      }
    }
  }
}

// common list class to format ul li 
.list-unstyled{
  list-style: none;
  padding: 0;
  margin: 0;
   li{
    padding: 0;
    margin: 0;
   }
}

// use this class for drag and drop item position issue while dragging 
.handle-drag-modal-position{
  .scrollable-modal__container {
    position: unset;
    margin: 60px auto 60px auto;
    transform: none;
  }
}

.loader{
  &__container{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    background-color: $white;
    display: flex;
    align-items: center;
    gap:10px;
    border-radius: 100px;
    padding: 12px 20px ;

    span{
      @include get-font($f14, $fw-m, $lightBlack, $lh);
    }
  }
}

//skeleton loaders
.skeleton-list {
  list-style: none;
  padding: 0;
  margin: 8px 0;

  &-item{
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: $grayBackground;
  }
  .skeleton-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $grayBackground;
    margin-right: 15px;
  }
  .skeleton-info {
    flex: 1;
    &-item {
      height: 10px;
      background-color: $grayBackground;
      margin-bottom: 5px;
      border-radius: 5px;
      width: 75%;
      &:first-child{
        width: 50%;
      }
      &:last-child{
        width: 100%;
      }
    }
  }
  @keyframes loading {
    0% { opacity: 0.7; }
    50% { opacity: 1; }
    100% { opacity: 0.7; }
  }
  .loading-animation {
    animation: loading 1.5s infinite;
  }

  //see all 
  &__see-all{
    padding: 24px;
    margin-top: 16px;
    .text{
      height: 12px;
      width: 75%;
      margin-bottom: 16px;
      border-radius: 5px;
      background-color: $grayBackground;
    }
    .avatar{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      .image{
        display: block;
        height: 50px;
        width: 50px;
        border-radius: 100%;
        background-color: $grayBackground;
      }
      .text-wrapper{
        display: flex;
        flex-direction: column;
        width: 200px;
        gap: 10px;
        .text{
          margin-bottom: 0;
        }
      }
    }
    footer{
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      .action{
        height: auto;
        margin: 8px 0;
        width: 20%;
      }
    }
    @keyframes loading {
      0% { opacity: 0.7; }
      50% { opacity: 1; }
      100% { opacity: 0.7; }
    }
    &.loading-animation {
      animation: loading 1.5s infinite;
    }
  }
}



// react video player common

.react-player{
  &__video{
    margin-top: 16px;
    video{
      aspect-ratio: 16/9;
      border-radius: 10px;
      background-color: $black;
    }
    &.no-border-radius{
      video{
        border-radius: unset;
      }
    }
    &.no_border_bottom_radius{
      video{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
    &.no-margin-top{
      margin-top: unset;
    }
    ::-webkit-media-controls-fullscreen-button{
      display: none;
    }
  }
}


// this class will remove outer padding from modal container div
.scrollable-modal__container.remove-outer-padding{
  padding: 0; // remove pading for modal container class
  > div {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    &:first-child{ // header padding 
      padding: 24px;
    }
    &.scrollable-modal__footer{ // footer
      margin-bottom: 0;
      padding: 12px 24px;
      &--paddingLessContentFalse::after{ // border for footer
        width: 100%;
        left: 0;
      }
    }
  }
  
}


.success-story {
  background-color: white;
  &__container{
    padding: 48px 20px;
    .story-content{
      max-width: 1164px;
      margin: 0 auto;
      width: 100%;
      &__title {
        @include get-font($f22, $fw-sb, $copiedBackground, $lh);
      }
      .story-content__container {
        display: flex;
        align-items: flex-start;
        gap: 44px;
        justify-content: center;
        margin-top: 20px;
        max-width: fit-content;
        .story__image{
          background-color: #F5F8FA;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 40px 48px;
          min-width: 332px;
          height: 312px;
          >img{
            width: auto;
            height: 164px;
          }
        }
        .details-container{
          .icon {
            width: auto;
            height: 33px
          }
          .title {
            @include get-font($f28, $fw-sb, #1F2937, $lh);
            margin-top: 12px;
            width: 90%;
          }
          .subtitle {
            @include get-font($f18, $fw-sb, #1F2937, $lh);
            margin-top: 12px;
            width: 90%;
          }
          .description{
            @include get-font($f16, $fw-m, #1F2937, $lh);
            margin-top: 16px;
            width: 90%;
          }
        }
      }
    }
  }

  // more success stories
  .more-story{
    background-color: #F5F8FA;
    margin-top: 16px;
    margin-bottom: 30px;
    &__container{
      max-width: 1164px;
      margin: 0 auto;
      width: 100%;
      padding-bottom: 32px;
      &__title {
        @include get-font($f32, $fw-sb, #1F2937, $lh);
        padding-top: 24px
      }
      &__description {
        @include get-font($f16, $fw-r, $copiedBackground, $lh);
        margin-top:16px;
      }
      .slider-div{
        .slick-slide{
          width: 357px !important;
          margin-right: 16px;
        }
      }
      .all-items-container{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        margin-top: 24px;
        gap: 12px;
        .item-container{
          background-color: white;
          display: flex;
          align-items: flex-start;
          gap: 16px;
          border-radius: 16px;
          padding: 24px 16px;
          .item-image{
            background-color: #F5F8FA;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            width: 92px;
            height: 100px;
            >img{
              width: auto;
              height: 50px
            }
          }
          .item-details{
            width: 156px;
            .icon {
              width: auto;
              height: 24px
            }
            .description {
            @include get-font($f14, $fw-sb, #1F2937, $lh);
            height: 88px;
            max-height: 88px;
            overflow: hidden;
            }
            .view-button {
              background-color: #F5F8FA;
              border-radius: 16px;
              padding: 4px 16px;
              @include get-font($f12, $fw-m, #1F2937, $lh);
              border: 1px solid #E5E7EB;
              margin-top: 4px;
              cursor: pointer;
              text-decoration: none;
              display: flex;
              align-items: center;
              width: fit-content;
              >img {
                margin-left: 4px;
              }
            }
          }
        }
        @media only screen and (max-width: $onlyMobile) {
          grid-template-columns: 1fr;
        }

        
      }
    }
  }
 
}

.carousel {
  .story-items{
    background: transparent;
    max-width: 100%;
  }
  .slick-slide{
    width: 468px !important;
  }
  .item-container{
    background-color: white;
    display: flex !important;
    align-items: flex-start;
    gap: 16px;
    border-radius: 16px;
    padding: 20px 16px;
    width: 420px !important;
    margin: 8px 0;
    .item-image{
      background-color: #F5F8FA;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      min-width: 92px;
      height: 100px;
      >img{
        width: auto;
        height: 50px
      }
    }
    .item-details{
      .icon {
        width: auto;
        height: 24px
      }
      .description {
      @include get-font($f14, $fw-sb, #1F2937, $lh);
      height: 88px;
      max-height: 88px;
      overflow: hidden;
      }
      .view-button {
        background-color: #F5F8FA;
        border-radius: 16px;
        padding: 4px 16px;
        @include get-font($f12, $fw-m, #1F2937, $lh);
        border: 1px solid #E5E7EB;
        margin-top: 4px;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        align-items: center;
        width: fit-content;
        >img {
          margin-left: 4px;
        }
      }
    }
  }
}

@media only screen and (max-width: $onlyMobile) {
  .success-story {
    &__container{
      padding: 24px 12px;
      .story-content{
        .story-content__container {
          flex-direction: column;
          gap: 20px;
          .story__image{
            width: 100%;
            height: fit-content;
            max-width: fit-content;
            min-width: -webkit-fill-available;

            img{
              width: 100%;
              height: auto;
            }
          }
          .details-container{
            .title {
              width: 100%;
            }
            .subtitle {
              width: 100%;
            }
            .description{
              width: 100%;
            }
          }
        }
      }
    }
    .more-story{
      margin-right: 12px;
      margin-left: 12px;
      padding: 12px;
    &__container{
      .all-items-container{
        .item-container{
          width: 100%;
          min-width: fit-content;
          max-width: 100%;
          box-sizing: border-box;
          .item-image{
            width: 27%;
            >img {
              height: 36%;
            }
          }
          .item-details{
            width: 57%;
          }
          .view-button {
            padding: 4px !important;
          }
        }
      }
    }
    }
  }
}

.retry-fetch-container{
  height: 100vh;
  max-width: 50%;
  margin: auto;
  display: flex;
  align-items: center;
}

.payment-loader {
  height: 50px;
  aspect-ratio: 2;
  border: 10px solid #c61b1b00;
  box-sizing: border-box;
  background: radial-gradient(farthest-side, $red 98%, #0000) left / 20px 20px, 
  radial-gradient(farthest-side, $red 98%, #0000) left / 20px 20px, 
  radial-gradient(farthest-side, $red 98%, #0000) center / 20px 20px, 
  radial-gradient(farthest-side, $red 98%, #0000) right / 20px 20px, #fff;
  background-repeat: no-repeat;
  filter: blur(4px) contrast(10);
  animation: l14 1s infinite;
}


@keyframes l14 {
  100%  {background-position:right,left,center,right}
}